
import { defineComponent } from 'vue';
import { AppWrapper } from '@/components';
import { URI_COMISSION_SUMMARY, URI_COMISSION, URI_COMISSION_OVERDUE_INVOICES, URI_COMISSION_ISSUED_INVOICES } from '@/api/endpoints';
import {
  DmAsyncContent,
  DmTable,
  DmIcon
} from '@dobrymechanik/vue-ui';
import dayjs from 'dayjs';
import { splitTextByDot } from '@/helpers/functions';
import { CommisionIssuedInvoices, CommisionInvoices } from '@/models/index';

enum EComissionTime {
  CurrentMonth,
  PreviousMonth
}

export default defineComponent({
  name: 'Comission',
  components: {
    AppWrapper,
    DmAsyncContent,
    DmTable,
    DmIcon
  },

  data () {
    return {
      debts: null as CommisionInvoices | null,
      invoices: null as CommisionIssuedInvoices | null,
      loading: true as boolean,
      currentRange: EComissionTime.CurrentMonth,
      ranges: [
        {
          text: 'Poprzedni miesiąc',
          key: EComissionTime.PreviousMonth,
          subtitle: dayjs().subtract(1, 'month').format('MM.YYYY')
        },
        {
          text: 'Bieżący miesiąc',
          key: EComissionTime.CurrentMonth,
          subtitle: dayjs().format('MM.YYYY')
        }
      ],
      list: [] as any[],
      summary: null as any,
      headers: [
        {
          text: 'Data wizyty',
          name: 'repair_date_confirmed'
        },
        {
          text: 'Powód wizyty',
          name: 'repair_description'
        },
        {
          text: 'Imię i nazwisko',
          name: 'driver_name'
        },
        {
          text: 'Nr telefonu',
          name: 'driver_phone'
        },
        {
          text: 'Samochód',
          name: 'car'
        },
        {
          text: 'Numer rejestracyjny',
          name: 'license_plate'
        },
        {
          text: 'Wartość naprawy (brutto)',
          name: 'visit_price'
        },
        {
          text: 'Prowizja',
          name: 'take_rate'
        },
        {
          text: 'Prowizja do zapłaty (netto)',
          name: 'commission'
        }
      ] as any[]
    };
  },
  computed: {
    currentMonth () {
      if (this.currentRange === EComissionTime.PreviousMonth) {
        return dayjs().subtract(1, 'month').format('MM');
      }
      return dayjs().format('MM');
    },
    currentYear () {
      if (this.currentRange === EComissionTime.PreviousMonth) {
        return dayjs().subtract(1, 'month').format('YYYY');
      }
      return dayjs().format('YYYY');
    }
  },

  async mounted () {
    this.fetchInitialData();
  },

  methods: {
    convertDate (date: string): string {
      return dayjs(date).format('DD.MM.YYYY');
    },
    splitTextByDot (text: string) {
      return splitTextByDot(text);
    },
    onRangeChange (range: EComissionTime) {
      this.currentRange = range;
      this.fetchInitialData();
    },
    async fetchInitialData () {
      this.loading = true;
      try {
        await this.GETComissionSummary();
        await this.GETComissionList();
        await this.GETcheckOverdueInvoices();
        await this.GETcheckIssuedInvoices();
      } catch {

      } finally {
        this.loading = false;
      }
    },
    async GETComissionList () {
      try {
        const data = (await this.$http.get<any[]>(URI_COMISSION(this.currentYear, this.currentMonth))).data;

        this.list = data.map(el => {
          return {
            ...el,
            repair_date_confirmed: dayjs(el.repair_date_confirmed).format('DD.MM.YYYY'),
            visit_price: `${el.visit_price.toFixed(2)} zł`,
            commission: `${el.commission.toFixed(2)} zł`,
            take_rate: `${el.take_rate} %`,
            repair_description: el.repair_description.length > 50 ? el.repair_description.substring(0, 50) + '...' : el.repair_description
          };
        });
      } catch {
        //
      }
    },
    async GETComissionSummary () {
      try {
        this.summary = (await this.$http.get<any>(URI_COMISSION_SUMMARY(this.currentYear, this.currentMonth))).data;
      } catch {
        //
      }
    },
    async GETcheckOverdueInvoices (): Promise<void> {
      try {
        const data = (await this.$http.get<CommisionInvoices>(URI_COMISSION_OVERDUE_INVOICES)).data;
        this.debts = data;
      } catch {
        //
      }
    },
    async GETcheckIssuedInvoices (): Promise<void> {
      try {
        const data = (await this.$http.get<CommisionIssuedInvoices>(URI_COMISSION_ISSUED_INVOICES)).data;
        this.invoices = data;
      } catch {
        //
      }
    }
  }
});
